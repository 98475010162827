import React, { useState } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share'

export default function SharePage({ classColor, page, url }) {
  const [openCondividi, setOpenCondividi] = useState(false)
  return (
    <div className="condividi">
      {openCondividi ? (
        <div onClick={() => setOpenCondividi(false)} className="pointer">
          <img src={`/condividi-page-${page}-close.svg`} alt="Condividi" />
          <span className="ml-1">Condividi</span>
        </div>
      ) : (
        <div onClick={() => setOpenCondividi(true)} className="pointer">
          <img src={`/condividi-page-${page}.svg`} alt="Condividi" />
          <span className="ml-1">Condividi</span>
        </div>
      )}

      {openCondividi && (
        <div className="d-flex flex-column display-block">
          <div>
            <span className={classColor}>↗</span>
            <span className="ml-2">
              <FacebookShareButton url={url}>Facebook</FacebookShareButton>
            </span>
          </div>
          <div>
            <span className={classColor}>↗</span>
            <span className="ml-2">
              <TwitterShareButton url={url}>Twitter</TwitterShareButton>
            </span>
          </div>
          <div>
            <span className={classColor}>↗</span>
            <span className="ml-2">
              <LinkedinShareButton url={url}>Linkedin</LinkedinShareButton>
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
