import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share'

export default function PageOne() {

  const urlPage = "https://stories.efficienzaenergetica.edison.it/soluzioni-digitali/"

  return (
    <div className="body-page-tablet">
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="d-flex block-condividi justify-content-between align-items-center">
            <div className="tempo-di-lettura d-flex align-items-center">
              <div className="linea bg-page-1 "></div>
              <div className="bg-page-1 text-white box-lettura">3′</div>
            </div>
            <div className="d-flex justify-content-between">
              <FacebookShareButton url={urlPage}>
                <img src={'/facebook-page-one.svg'} alt="Facebook" />
              </FacebookShareButton>
              <TwitterShareButton url={urlPage}>
                <img src={'/twitter-page-one.svg'} alt="Twitter" />
              </TwitterShareButton>
              <LinkedinShareButton url={urlPage}>
                <img src={'/linkedin-page-one.svg'} alt="Linkedin" />
              </LinkedinShareButton>
            </div>
          </div>
          <h3 className="title-page color-page-1">
            Perchè il digitale è sempre più importante
          </h3>
        </div>
        <div className="offset-md-2 col-md-8">
          <div className="text-page">
            <div>
              <div className="number-page color-page-1">1</div>
              <div>
                Le aziende si trovano sempre più spesso e urgentemente a dover
                rispondere in modo efficace alle sfide dettate dalla
                digitalizzazione, facendo spazio a nuovi comparti e figure
                professionali. Lo sviluppo di prodotti innovativi, così come
                l’introduzione di novità tecniche e la riprogettazione dei
                processi produttivi, è ormai legata a doppio filo allo studio
                dei dati, che aiutano ad orientare le scelte manageriali.
              </div>
              <div className="mt-4">
                In quest’ottica, oggi conviene non basarsi più soltanto su idee
                e risorse interne, ma aprirsi a tecnologie e competenze che
                arrivano dall’esterno. Per questo è fondamentale avere ben
                chiaro ciò che accade nel mondo delle università, dei centri di
                ricerca o delle startup: studiare le idee più virtuose e
                innovative permette di non rimanere spiazzati davanti ai cambi
                di rotta del proprio mercato di riferimento.
              </div>
              <div className="mt-4">
                In un contesto in continuo cambiamento come quello energetico,
                in cui si muove Edison, è ancor più importante individuare nuove
                sfide e opportunità per indirizzare correttamente gli
                investimenti, trasmettere valore al cliente, generare maggiori
                fonti di risparmio, favorire la sostenibilità e differenziare il
                business.
              </div>
            </div>
          </div>
        </div>
        <div className="offset-md-4 col-md-4">
          <div className="external-link-page-1 margin-top-32">
            <a
              href="https://www.youtube.com/watch?v=iPI_oX-rjx4"
              target="_blank"
              title="Soluzioni digitali di Edison"
              rel="noopener noreferrer"
            >
              Le <span className="color-page-1">soluzioni digitali</span> di
              Edison:
              <br />
              guarda il video <span className="color-page-1">↗</span>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="color-page-1 citazione citazione-page-1">
            Il digitale, inteso come la padronanza del dato, la sua gestione, il
            suo utilizzo e la sua messa a fattore comune attraverso piattaforme
            open, ci aiuta a migliorare la nostra proposta di modelli di
            efficienza e a trovare un nuovo modo di interazione con il cliente.
          </div>
          <div className="d-flex align-items-center mt-3">
            <img src={'/paolo-quaini.png'} alt="Paolo Quaini" />
            <div className="color-page-1 citazione-nome">
              Paolo Quaini, Direttore Edison Servizi Energetici e Ambientali
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="text-page">
            <div>
              È per questo che l’azienda ha scommesso sia sulla costruzione di
              nuove competenze interne che sull’alfabetizzazione dei clienti
              all’utilizzo di strumenti quali l’Internet of things,
              l’intelligenza artificiale e la data analysis.
            </div>
            <div className="mt-4">
              Il digitale è infatti una chiave di lettura dello sviluppo: è al
              contempo servizio e metodologia, perché aiuta a monitorare,
              analizzare e gestire – in efficienza e nel corso del tempo – i
              processi industriali, conferendo agli stessi edifici che ospitano
              le aziende un’anima “smart”.
            </div>
          </div>
        </div>
        <div className='offset-md-4 col-md-4'>
          <div className="external-link-page-1 margin-top-32 margin-bottom-40">
            <a
              href="https://www.youtube.com/watch?v=p7QEEfGRN2w&feature=emb_title"
              target="_blank"
              rel="noopener noreferrer"
              title="La trasformazione digitale di Edison"
            >
              La <span className="color-page-1">trasformazione digitale</span>{' '}
              di Edison:
              <br />
              guarda il video <span className="color-page-1">↗</span>
            </a>
          </div>
        </div>
        <div className='offset-md-2 col-md-8'>
          <div className='text-page margin-top-0'>
            <div className="mt-4">
              Dal 2017 Edison ha avviato un ampio progetto di trasformazione
              digitale, che ora caratterizza tutto il suo modo operare. Un
              approccio, questo, basato sullo scambio continuo con il mondo
              dell’innovazione, al fine di facilitare lo sviluppo di nuove
              progettualità e la circolarità dei saperi.
            </div>
            <div className="mt-4">
              L'approccio all'innovazione e alla digitalizzazione di Edison sono
              focali nel modello di business che la{' '}
              <span className="link-page-1">
                <a
                  href="https://www.efficienzaenergetica.edison.it/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  unit Servizi Energetici e Ambientali
                </a>
              </span>{' '}
              sta sviluppando insieme e per i propri Clienti, abbracciando a
              pieno i presupposti e le caratteristiche dell'Industria 4.0.{' '}
              <span>
                <img src={'/edison-page-one-mini.svg'} alt="Edison" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
