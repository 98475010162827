import React, { useContext } from "react";
import { MobileContext } from "../providers/MobileProvider";
import MobileContainer from "./MobileContainer";
import TabletContainer from "./TabletContainer";
import DesktopContainer from "./DesktopContainer";

export default function Container({ children }) {

  const typeDevice = useContext(MobileContext);

  return (
    <>
      {typeDevice.desktop && <DesktopContainer children={children} />}
      {typeDevice.tablet && <TabletContainer />}
      {typeDevice.mobile && <MobileContainer />}
      
    </>
  );
}
