import React from "react";

export default function YoutubeIcon({ className }) {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        d="M17.2783 1.92904C17.0754 1.19029 16.4774 0.608477 15.7181 0.41103C14.3419 0.0522461 8.82353 0.0522461 8.82353 0.0522461C8.82353 0.0522461 3.30517 0.0522461 1.92894 0.41103C1.16969 0.608508 0.571709 1.19029 0.368753 1.92904C0 3.26806 0 6.0618 0 6.0618C0 6.0618 0 8.85555 0.368753 10.1946C0.571709 10.9333 1.16969 11.4909 1.92894 11.6883C3.30517 12.0471 8.82353 12.0471 8.82353 12.0471C8.82353 12.0471 14.3419 12.0471 15.7181 11.6883C16.4774 11.4909 17.0754 10.9333 17.2783 10.1946C17.6471 8.85555 17.6471 6.0618 17.6471 6.0618C17.6471 6.0618 17.6471 3.26806 17.2783 1.92904ZM7.01871 8.59831V3.52529L11.631 6.06187L7.01871 8.59831Z"
        fill="#353538"
      />
    </svg>
  );
}
