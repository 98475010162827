import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share'

export default function PageThree({ children }) {

  const urlPage = "https://stories.efficienzaenergetica.edison.it/soluzioni-digitali/dati-e-industria/"

  return (
    <div className="body-page-mobile">
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="d-flex block-condividi justify-content-between align-items-center">
            <div className="tempo-di-lettura d-flex align-items-center">
              <div className="linea bg-page-3 "></div>
              <div className="bg-page-3 text-white box-lettura">4′</div>
            </div>
            <div className="d-flex justify-content-between">
              <FacebookShareButton url={urlPage}>
                <img src={'/facebook-page-three.svg'} alt="Facebook" />
              </FacebookShareButton>
              <TwitterShareButton url={urlPage}>
                <img src={'/twitter-page-three.svg'} alt="Twitter" />
              </TwitterShareButton>
              <LinkedinShareButton url={urlPage}>
                <img src={'/linkedin-page-three.svg'} alt="Linkedin" />
              </LinkedinShareButton>
            </div>
          </div>
          <h3 className="title-page color-page-3">
            l'ottimizzazione energetica tramite i dati
          </h3>
          <div className="text-page">
            <div>
              <div className="number-page color-page-3">3</div>
              <div>
                Il dato energetico è, per sua stessa natura, centrale nel
                settore industriale, perché permette di valutare le prestazioni
                energetiche del cliente e di conseguenza provare a migliorarle.
                Leggere in maniera intelligente i numeri, inoltre, consente un
                maggiore controllo e consapevolezza sulla propria struttura
                produttiva, nonché di ottenere informazioni utili da sfruttare
                nelle proprie strategie.
              </div>
              <div className="mt-4">
                La gestione energetica e ambientale di qualsiasi “sistema” non è
                una formula matematica, ma un articolato insieme di opzioni che
                richiede competenze e tempo per essere valutato. Spesso,
                infatti, è difficile far comprendere all’interlocutore la
                differenza tra la massimizzazione di valore economico nel breve
                periodo, e la minimizzazione del costo complessivo di una
                gestione ottimizzata nel tempo: se fino a poco tempo fa era
                inimmaginabile leggere insieme al cliente i “dati”, ora tutto
                questo è possibile e sempre più frequente.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row margin-top-32">
        <div className="offset-md-1 col-sm-4">
          <div className="text-center">
            <div className="border-small-page-3"></div>
            <div className="font-14">
              Quanta energia consumano
              <br />
              le aziende
            </div>
            <span className="big-number color-page-3">20</span>
            <span className="big-number-unit color-page-3">%</span>
            <div className="font-14">
              I consumi energetici delle
              <br />
              aziende rispetto ai consumi
              <br />
              totali di energia a livello
              <br />
              nazionale
            </div>
            <div className="mt-3 mb-3">
              <img src={'/arrow-down-page-three.svg'} alt="Arrow Down" />
            </div>
            <div className="slogan-strong">
              Questo equivale ad avere
              <br />
              un’enorme mole di dati
              <br />
              energetici disponibili
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="text-page margin-top-0">
            <div>
              A partire dalla raccolta, gestione e valorizzazione dei dati,
              Edison ha configurato una gamma di servizi digitali che
              prospettano soluzioni dinamiche, specializzate e al contempo
              personalizzate, per accompagnare le aziende nella costruzione di
              valore e per generare un risparmio economico e ambientale.
            </div>
            <div className="mt-3">
              Edison propone alle realtà industriali opportunità a partire
              proprio dall’analisi del dato e del suo utilizzo. L'azienda fa
              questo attraverso il monitoraggio e l’ottimizzazione dei consumi
              in tempo reale: al fine di individuare nuove aree di efficienza,
              sfrutta algoritmi basati su intelligenza artificiale e machine
              learning per controllare gli asset di campo e realizzare
              importanti risparmi energetici.
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-10">
          <div className="subtitle color-page-3">
            LA DIVISIONE EDISON SERVIZI ENERGETICI E AMBIENTALI NEL 2019
          </div>
          <img
            src={'/infografica-page-3-tablet.svg'}
            alt="Infografica"
            width="100%"
            className="m-auto"
          />
        </div>
      </div>
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="text-page">
            <div>
              In questo filone si inserisce ad esempio l’accordo siglato da
              Edison con Metron, per il servizio{' '}
              <span className="link-page-3">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.efficienzaenergetica.edison.it/servizi/soluzioni-digitali/Edison-analytics-powered-by-metron/"
                >
                  Edison Analytics powered by Metron
                </a>
              </span>
              , volto a offrire ai clienti industriali gli strumenti necessari
              per essere consapevoli rispetto a quanto e come consumano, così
              come il know-how per definire strategie energetiche di lungo
              periodo, grazie all’Intelligenza Artificiale basata sul machine
              learning.
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-4 col-md-4 margin-top-32 margin-bottom-40">
          <div className="external-link-page-3">
            <a
              href="https://www.edison.it/it/edison-metron-consumi-energetici"
              target="_blank"
              title="Partnership con Metron"
              rel="noopener noreferrer"
            >
              La <span className="color-page-3">partnership</span> con Metron:
              <br /> leggi il comunicato <span className="color-page-3">↗</span>
            </a>
          </div>
        </div>
        <div className="offset-md-2 col-md-8">
          <div className="color-page-3 citazione citazione-page-3 margin-top-0">
            Questa nuova partnership sottolinea l’emergere di una nuova e
            diffusa consapevolezza all’interno dell’industria italiana, che si
            traduce in una forte volontà di muoversi verso un ecosistema più
            sostenibile.
          </div>
          <div className="d-flex align-items-center mt-3">
            <img src={'/vincent-sciandra.png'} alt="Vincent Sciandra" />
            <div className="color-page-3 citazione-nome">
              Vincent Sciandra, Ceo di Metron
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="text-page">
            <div>
              Alla consapevolezza e alla conoscenza maggiore dei propri consumi,
              la soluzione Edison Analytics powered by Metron unisce poi la
              possibilità di correlare e contestualizzare i dati che sono stati
              raccolti, così da proporre soluzioni avanzate di business che
              integrano dati di campo, kpi di produzione e altri fattori.
            </div>
            <div className="mt-4">
              In definitiva, le competenze assodate di ingegneria energetica di
              Edison si intrecciano oggi con un nuovo know-how di information
              technology. Questo permette di generare un nuovo modo di lavorare,
              reattivo e votato all’innovazione digitale, che accompagna i
              clienti nella presa di coscienza rispetto alla potenzialità dei
              loro dati energetici.
              <span className="ml-1">
                <img src={'/edison-page-three-mini.svg'} alt="Edison" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>
    </div>
  )
}
