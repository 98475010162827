import React from "react";

export default function TwitterCircleIcon({ className }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="14.5" fill="white" stroke="black" />
      <path
        d="M6.696 9.2V10.446H9.86V19H11.246V10.446H14.41V9.2H6.696ZM13.8783 12.126L15.4323 19H17.3223L18.7783 13.288H18.9183L20.3743 19H22.2643L23.8183 12.126H22.4883L21.3543 17.838H21.2143L19.7583 12.126H17.9383L16.4823 17.838H16.3423L15.2083 12.126H13.8783Z"
        fill="black"
      />
    </svg>
  );
}
