import React from "react";

export default function FacebookCircleIcon({ className }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="14.5"  stroke="black" />
      <path
        d="M8.452 9.2V19H9.838V14.716H14.262V13.456H9.838V10.446H14.542V9.2H8.452ZM15.8618 9.2V19H17.1498V17.908H17.2898C17.7098 18.58 18.4238 19.196 19.7678 19.196C21.5178 19.196 22.9738 17.796 22.9738 15.57C22.9738 13.344 21.5178 11.93 19.7678 11.93C18.4238 11.93 17.7098 12.546 17.3178 13.19H17.1778V9.2H15.8618ZM19.4178 13.078C20.7198 13.078 21.6438 14.002 21.6438 15.57C21.6438 17.124 20.7198 18.048 19.4178 18.048C18.1018 18.048 17.1638 17.11 17.1638 15.57C17.1638 14.016 18.1018 13.078 19.4178 13.078Z"
        fill="black"
      />
    </svg>
  );
}
