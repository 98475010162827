import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share'

export default function PageFour({ children }) {
  const urlPage =
    'https://stories.efficienzaenergetica.edison.it/soluzioni-digitali/le-proposte-edison/'

  return (
    <div className="body-page-mobile">
      <div className="row">
        <div className="col-sm-12">
          <div className="d-flex block-condividi justify-content-between align-items-center">
            <div className="tempo-di-lettura d-flex align-items-center">
              <div className="linea bg-page-4"></div>
              <div className="bg-page-4 text-white box-lettura">5′</div>
            </div>
            <div className="d-flex justify-content-between">
              <FacebookShareButton url={urlPage}>
                <img src={'/facebook-page-four.svg'} alt="Facebook" />
              </FacebookShareButton>
              <TwitterShareButton url={urlPage}>
                <img src={'/twitter-page-four.svg'} alt="Twitter" />
              </TwitterShareButton>
              <LinkedinShareButton url={urlPage}>
                <img src={'/linkedin-page-four.svg'} alt="Linkedin" />
              </LinkedinShareButton>
            </div>
          </div>
          <h1 className="title-page color-page-4">
            Le soluzioni digitali per l’Industria 4.0
          </h1>
          <div className="text-page">
            <div>
              <div className="number-page color-page-4">4</div>
              <div>
                Alla base delle proposte digitali di Edison trovano spazio la
                raccolta, la comprensione, la visualizzazione e, infine, la
                valorizzazione del dato – tutti aspetti, questi, che integrati
                permettono agli interlocutori di Edison di sperimentare un nuovo
                modo di fare business.
              </div>
              <div className="mt-4">
                Edison è in grado di comprendere e presidiare tutte le fasi
                della catena del valore del dato: dalla rilevazione attenta alla
                conoscenza dei KPI “di campo”, dalla gerenza del dato (come la
                trasmissione, la gestione di data platform o la data storage) al
                suo utilizzo secondo standard di privacy e norme – è la
                cosiddetta data quality.
              </div>
              <div className="mt-4">
                Le competenze di Edison all’interno del settore digitale sono
                frutto dell’esperienza maturata sul campo: l’azienda infatti
                unisce le tradizionali attività di gestione e manutenzione degli
                impianti e di ingegneria energetica con la forte conoscenza
                nella raccolta e gestione dei dati.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="subtitle margin-top-32 color-page-4">
            RENDERE VISIBILE LA SPESA ENERGETICA
          </div>
          <hr className="hr-page-4" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-flex align-items-center">
          <div className="color-page-4 number-page-4">1</div>
          <div className="ml-5">
            <div className="color-page-4 ml-3 font-weight-bold">
              Rilevazione dati
            </div>
            <div className="ml-3">
              Analisi e mappatura situazione
              <br />
              di campo, e progettazione e<br />
              installazione di sistemi
              <br />
              di rilevazione-dati.
            </div>
          </div>
        </div>
      </div>
      <div className="border-top-mobile-3"></div>
      <div className="row">
        <div className="col-sm-12 d-flex align-items-center">
          <div className="color-page-4 number-page-4">2</div>
          <div className="ml-5">
            <div className="color-page-4 font-weight-bold">Gestione dati</div>
            <div>
              Progettazione e realizzazione
              <br />
              di sistemi di trasmissione e<br />
              aggregazione dati,
              <br />e gestione della data quality.
            </div>
          </div>
        </div>
      </div>
      <div className="border-top-mobile-3"></div>
      <div className="row">
        <div className="col-sm-12 d-flex align-items-center">
          <div className="color-page-4 number-page-4">3</div>
          <div className="ml-5">
            <div className="color-page-4 font-weight-bold">
              Servizi di ottimizzazione
            </div>
            <div>
              Ottimizzazione dei consumi energetici basata
              <br />
              sull’analisi avanzata dei dati,
              <br />
              con il controllo
              <br />
              dei parametri operativi.
            </div>
          </div>
        </div>
      </div>
      <div className="border-top-mobile-3"></div>
      <div className="row">
        <div className="col-sm-12 d-flex align-items-center">
          <div className="color-page-4 number-page-4">4</div>
          <div className="ml-5">
            <div className="color-page-4 font-weight-bold">
              Monitoraggio KPI e analisi dati
            </div>
            <div>
              Soluzioni real time di monitoraggio,
              <br />
              analisi e previsione dei
              <br />
              consumi elettrici, termici
              <br />e CO2 per i processi
              <br />
              industriali e gli edifici
            </div>
          </div>
        </div>
      </div>
      <div className="border-top-mobile-3"></div>
      <div className="row">
        <div className="col-sm-12 d-flex align-items-center">
          <div className="color-page-4 number-page-4">5</div>
          <div className="ml-5">
            <div className="color-page-4 font-weight-bold">
              Business Energy Intelligence
            </div>
            <div>
              Analisi avanzata dei consumi energetici
              <br />
              per l’identificazione,
              <br />
              valutazione e progettazione di <br />
              opportunità efficienziali.
            </div>
          </div>
        </div>
      </div>
      <div className="border-top-mobile-3"></div>
      <div className="row">
        <div className="offset-md-3 col-md-8 text-page">
          <div>
            In ambito industriale Edison opera con{' '}
            <span className="link-page-4">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.efficienzaenergetica.edison.it/servizi/soluzioni-digitali/"
              >
                soluzioni di Energy Management System
              </a>
            </span>{' '}
            che permettono la raccolta, consuntivazione e la gestione dei dati
            energetici e operativi:{' '}
            <span className="link-page-4">
              <a
                href="https://www.efficienzaenergetica.edison.it/servizi/soluzioni-digitali/edison-smart-audit/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Smart Audit
              </a>
            </span>
            , dedicato alle medie imprese energivore, nonché alle Grandi
            imprese, soprattutto del settore Terziario; ed Edison Analitycs
            powered by Metron che si rivolge alle grandi aziende.
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-flex">
          <div className="text-page">
            <img src={'/number-one-red.svg'} alt="Number One" />
          </div>
          <div className="external-link-vertical-16 mt-4 font-14 ml-2 color-page-4">
            PMI e terziario
          </div>
        </div>
        <div className="col-sm-12">
          <div className="text-page">
            Smart Audit è un servizio di monitoraggio, analisi e forecasting dei
            consumi energetici, che include report periodici di consulenza
            energetica e diagnosi energetica, consentendo un risparmio fino al
            10% dei consumi energetici totali grazie agli interventi gestionali.
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-flex">
          <div className="text-page">
            <img src={'/number-2-red.svg'} alt="Number Two" />
          </div>
          <div className="external-link-vertical-16 mt-4 ml-2 font-14 color-page-4">
            Grandi aziende
          </div>
        </div>
        <div className="col-sm-12">
          <div className="text-page">
            Edison Analytics powered by Metron invece sfrutta le fonti di dati
            già esistenti nelle industrie italiane: la piattaforma digitale
            raccoglie, aggrega e analizza in tempo reale i dati generati
            dall’impianto, interagendo al contempo i dati provenienti
            dall’esterno, permettendo di identificare opportunità di
            ottimizzazione energetica.
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="color-page-4 citazione citazione-page-4">
            La soluzione si può applicare a tutti i processi industriali di
            qualsiasi settore produttivo, ed è in grado di prevedere le future
            esigenze energetiche di un impianto, individuare nuove opportunità e
            soluzioni di efficientamento, e se necessario ottimizzare anche la
            gestione delle singole macchine.
          </div>
          <div className="d-flex align-items-center mt-3">
            <img src={'/paolo-quaini-2.png'} alt="Paolo Quaini" />
            <div className="color-page-4 citazione-nome">
              Paolo Quaini, Direttore Edison Servizi Energetici e Ambientali
            </div>
          </div>
        </div>
      </div>
      <div className="row margin-top-32">
        <div className="col-sm-12">
          <div className="text-center">
            <div className="font-14">Quanto si può risparmiare</div>
            <span className="big-number color-page-4">14</span>
            <span className="big-number-unit color-page-4">%</span>
            <div className="font-14">
              Il risparmio consentito
              <br />
              da Edison Analytics powered
              <br />
              by Metron rispetto ai consumi
              <br />
              energetici totali
            </div>
            <div className="mt-3 mb-3">
              <img src={'/arrow-down-page-fuor.svg'} alt="Arrow Down" />
            </div>
            <div className="slogan-strong">
              Questo grazie all’analisi
              <br />
              dei dati storici e prevedendo
              <br />
              le esigenze energetiche
              <br />
              dell’impianto
            </div>
          </div>
          <hr className="hr-page-4 margin-top-24" />
          <div className="text-page margin-top-32">
            <div>
              La soluzione si fonda sull’impiego dell’Intelligenza Artificiale,
              che utilizza modelli energetici evoluti in grado di individuare e
              valutare correlazioni non-intuitive tra un grande numero di
              variabili, cosa che consente – a sua volta – di tracciare
              soluzioni di efficientamento.
            </div>
            <div className="mt-3">
              Attraverso algoritmi basati su AI, Edison Analytics powered by
              Metron è quindi in grado di valutare i setting ottimali degli
              impianti monitorati garantendo l’ottimizzazione dei consumi,
              intervenendo direttamente e indirettamente sul controllo degli
              impianti.
            </div>
            <div className="mt-3">
              Nello specifico, dopo aver creato un modello digitale
              dell’impianto deputato vengono utilizzati il machine learning e
              l’intelligenza artificiale per ottimizzare i parametri operativi,
              riducendo i consumi energetici.
              <span className="ml-1">
                <img src={'/edison-page-fuor-mini.svg'} alt="Edison" />
              </span>
              <a href={'#page1'}>
                <span className="color-page-4 torna-link ml-1 font-12">
                  <u>torna al capitolo 1</u>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="subtitle margin-top-32 color-page-4">
            CASI DI Ottimizzazione ENERGETICA nei processi industriali
          </div>
          <hr className="hr-page-4" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-flex">
          <div className="color-page-4 font-weight-bold font-14">
            <img src="/key-icon.svg" alt="Key Icon" />
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-24">
          <div className="color-page-4 w-table label-page-4 font-14">
            Cliente
          </div>
          <div className="font-14 ml-2 description-page-4">
            Grande industria siderurgica e<br />
            mineraria
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-24">
          <div className="color-page-4 w-table label-page-4 font-14">
            Problema
          </div>
          <div className="font-14 ml-2 description-page-4">
            Ottimizzare la gestione energetica di
            <br />
            una delle proprie fabbriche
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-24">
          <div className="color-page-4 w-table label-page-4 font-14">
            Soluzione
          </div>
          <div className="font-14 ml-2 description-page-4">
            Monitoraggio – continuo e in tempo
            <br />
            reale – e comunicazione
            <br />
            automatizzata dei consumi e<br />
            dei flussi di energia
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-24">
          <div className="color-page-4 w-table label-page-4 font-14">
            Risultati
          </div>
          <div className="font-14 ml-2 description-page-4">
            Risparmio di tempo e riduzione del
            <br />
            proprio budget energetico a parità
            <br />
            di consumo
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-24">
          <div className="color-page-4 w-table label-page-4 font-14">
            Risparmi
          </div>
          <div className="font-14 ml-2 description-page-4">
            340 mila €/anno
            <br />5 giorni-uomo/mese per la
            <br />
            raccolta dati risparmiati
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-32">
          <div className="color-page-4 label-page-4 font-14">
            <img src="/document-icon.svg" alt="Document Icon" />
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-24">
          <div className="color-page-4 w-table label-page-4 font-14">
            Cliente
          </div>
          <div className="font-14 ml-2 description-page-4">
            Società di produzione di prodotti
            <br />
            in carta
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-24">
          <div className="color-page-4 w-table label-page-4 font-14">
            Problema
          </div>
          <div className="font-14 ml-2 description-page-4">
            Ridurre il processo e il consumo
            <br /> di vapore delle macchine
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-24">
          <div className="color-page-4 w-table label-page-4 font-14">
            Soluzione
          </div>
          <div className="font-14 ml-2 description-page-4">
            Digitalizzazione delle macchine,
            <br />
            creando un “gemello digitale"
            <br />
            attraverso i dati, per visualizzare
            <br />e ottimizzare il consumo di energia
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-24">
          <div className="color-page-4 w-table label-page-4 font-14">
            Risultati
          </div>
          <div className="font-14 ml-2 description-page-4">
            Risparmio di tempo e riduzione del
            <br />
            proprio budget energetico a parità
            <br />
            di consumo
          </div>
        </div>
        <div className="col-sm-12 d-flex margin-top-24">
          <div className="color-page-4 w-table label-page-4 font-14">
            Risparmi
          </div>
          <div className="font-14 ml-2 description-page-4">
            270 mila €/anno
            <br />1 giorni-uomo/mese per la
            <br />
            raccolta dati risparmiati
          </div>
        </div>
      </div>
    </div>
  )
}
