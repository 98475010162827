import React from "react";

export default function FacebookIcon({ className }) {
  return (
    <svg
      width="9"
      height="17"
      viewBox="0 0 9 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        d="M7.73354 9.04924L8.16269 6.1549H5.47942V4.27667C5.47942 3.48483 5.85425 2.71298 7.056 2.71298H8.27586V0.248761C8.27586 0.248761 7.16887 0.0532227 6.11047 0.0532227C3.90072 0.0532227 2.45632 1.43948 2.45632 3.949V6.1549H0V9.04924H2.45632V16.0461H5.47942V9.04924H7.73354Z"
        fill="#353538"
      />
    </svg>
  );
}
