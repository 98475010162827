import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useDimensions from "react-use-dimensions";
import "d3-transition";
import { selectAll } from "d3-selection";
import Slide from "react-reveal/Slide";

export default function DesktopContainer({ children }) {
  const history = useHistory();
  const location = useLocation();
  const [ref, { width }] = useDimensions();

  const availableSpace = width ? width - 65 * 2 : 0;

  const currentPage =
    location.pathname === "/"
      ? "edison-e-il-digitale"
      : location.pathname.slice(1);

  function linkTo(page) {
    const currentContainer = `#container-${currentPage}`;
    const targetContaienr = `#container-${page}`;

    selectAll(currentContainer).transition().style("width", "0px");

    selectAll(targetContaienr)
      .transition()
      .style("width", `${availableSpace}px`)
      .end()
      .then(() => {
        history.push(`/${page}`);
      });
  }

  return (
    <div className="d-flex flex-row vh-100" ref={ref}>
      {availableSpace && (
        <>
          <div
            className="page1-column link-column"
            onClick={() => {
              linkTo("edison-e-il-digitale");
            }}
          >
            <p className="d-flex">
              <span>Edison e il digitale</span>
            </p>
          </div>

          <div
            style={{
              width:
                currentPage === "edison-e-il-digitale" ? availableSpace : 0,
            }}
            className="desktop-container"
            id={`container-edison-e-il-digitale`}
          >
            {currentPage === "edison-e-il-digitale" && children}
          </div>

          <Slide top delay={300}>
            <div
              className="page2-column link-column"
              onClick={() => {
                linkTo("industria-4-0");
              }}
            >
              <p className="d-flex">
                <span>Cos’è l’Industria 4.0</span>
              </p>
            </div>
          </Slide>
          <div
            style={{
              width:
                currentPage === "industria-4-0" ? availableSpace : 0,
            }}
            className="desktop-container"
            id={`container-industria-4-0`}
          >
            {currentPage === "industria-4-0" && children}
          </div>
          <Slide top delay={200}>
            <div
              className="page3-column link-column"
              onClick={() => {
                linkTo("dati-e-industria");
              }}
            >
              <p className="d-flex">
                <span>I dati e l’industria</span>
              </p>
            </div>
          </Slide>
          <div
            style={{
              width: currentPage === "dati-e-industria" ? availableSpace : 0,
            }}
            className="desktop-container"
            id={`container-dati-e-industria`}
          >
            {currentPage === "dati-e-industria" && children}
          </div>
          <Slide top delay={100}>
            <div
              className="page4-column link-column"
              onClick={() => {
                linkTo("le-proposte-edison");
              }}
            >
              <p className="d-flex">
                <span>Le soluzioni digitali</span>
              </p>
            </div>
          </Slide>
          <div
            style={{
              width:
                currentPage === "le-proposte-edison" ? availableSpace : 0,
            }}
            className="desktop-container"
            id={`container-le-proposte-edison`}
          >
            {currentPage === "le-proposte-edison" && children}
          </div>
        </>
      )}
    </div>
  );
}
