import React from "react";

export default function InstagramIcon({ className }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        d="M8.00178 3.9493C5.73162 3.9493 3.9005 5.78002 3.9005 8.04968C3.9005 10.3193 5.73162 12.1501 8.00178 12.1501C10.2719 12.1501 12.1031 10.3193 12.1031 8.04968C12.1031 5.78002 10.2719 3.9493 8.00178 3.9493ZM8.00178 10.7155C6.53475 10.7155 5.33541 9.51997 5.33541 8.04968C5.33541 6.5794 6.53118 5.3839 8.00178 5.3839C9.47239 5.3839 10.6682 6.5794 10.6682 8.04968C10.6682 9.51997 9.46882 10.7155 8.00178 10.7155ZM13.2274 3.78157C13.2274 4.3133 12.7991 4.73797 12.2708 4.73797C11.739 4.73797 11.3142 4.30973 11.3142 3.78157C11.3142 3.25341 11.7426 2.82517 12.2708 2.82517C12.7991 2.82517 13.2274 3.25341 13.2274 3.78157ZM15.9438 4.75225C15.8831 3.4711 15.5904 2.33627 14.6516 1.40128C13.7165 0.466294 12.5814 0.173665 11.2999 0.109429C9.97925 0.0344872 6.02075 0.0344872 4.70006 0.109429C3.4222 0.170096 2.28712 0.462726 1.34835 1.39771C0.409593 2.3327 0.120468 3.46753 0.0562186 4.74868C-0.0187395 6.06908 -0.0187395 10.0267 0.0562186 11.3471C0.116899 12.6283 0.409593 13.7631 1.34835 14.6981C2.28712 15.6331 3.41863 15.9257 4.70006 15.9899C6.02075 16.0649 9.97925 16.0649 11.2999 15.9899C12.5814 15.9293 13.7165 15.6366 14.6516 14.6981C15.5868 13.7631 15.8795 12.6283 15.9438 11.3471C16.0187 10.0267 16.0187 6.07265 15.9438 4.75225ZM14.2376 12.7639C13.9592 13.4633 13.4202 14.0022 12.717 14.2841C11.664 14.7017 9.16542 14.6053 8.00178 14.6053C6.83815 14.6053 4.33597 14.6981 3.28656 14.2841C2.58695 14.0058 2.04796 13.4669 1.76598 12.7639C1.34835 11.7111 1.44473 9.21306 1.44473 8.04968C1.44473 6.8863 1.35192 4.38468 1.76598 3.33549C2.04439 2.63604 2.58338 2.09717 3.28656 1.81525C4.33954 1.39771 6.83815 1.49407 8.00178 1.49407C9.16542 1.49407 11.6676 1.40128 12.717 1.81525C13.4166 2.0936 13.9556 2.63247 14.2376 3.33549C14.6552 4.38824 14.5588 6.8863 14.5588 8.04968C14.5588 9.21306 14.6552 11.7147 14.2376 12.7639Z"
        fill="#353538"
      />
    </svg>
  );
}
