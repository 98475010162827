import React from "react";
import './RotateSmartphone.css'

export default function RotateSmartphone() {
  return (
    <div className="box-landscape flex-column align-items-center">
      <img src="/edison-mini-logo-ruota.svg" alt="Edison" />
      <div className="rotate-smartphone margin-top-24">
        RUOTA IL DISPOSITIVO
      </div>
      <div className="margin-top-24">
        Teniamo all’esperienza di lettura dei nostri utenti,
        <br />
        per questo invitiamo a mantenere il dispositivo
        <br />
        in dimensione verticale e fruire il progetto in questo modo.
      </div>
    </div>
  );
}
