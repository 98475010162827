import React from "react";

export default function SendMessageIcon({ className }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="14.5" fill="white" stroke="black" />
      <path
        d="M12.326 10.216L12.344 11.53H16.142L16.79 11.422L16.88 11.566L16.358 11.998L10.13 18.244L11.12 19.234L17.366 12.988L17.78 12.484L17.942 12.556L17.834 13.204V17.02H19.148V10.99L18.374 10.216H12.326Z"
        fill="black"
      />
    </svg>
  );
}
