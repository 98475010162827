import React from "react";

export default function LinkedinIcon({ className }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        d="M3.58143 16.0461H0.264286V5.36849H3.58143V16.0461ZM1.92107 3.91196C0.860357 3.91196 0 3.03376 0 1.97349C7.59214e-09 1.4642 0.202398 0.975774 0.562669 0.615655C0.92294 0.255536 1.41157 0.0532227 1.92107 0.0532227C2.43057 0.0532227 2.9192 0.255536 3.27947 0.615655C3.63974 0.975774 3.84214 1.4642 3.84214 1.97349C3.84214 3.03376 2.98143 3.91196 1.92107 3.91196ZM15.9964 16.0461H12.6864V10.8483C12.6864 9.60956 12.6614 8.02095 10.9618 8.02095C9.23714 8.02095 8.97286 9.36681 8.97286 10.7591V16.0461H5.65929V5.36849H8.84071V6.82502H8.88714C9.33 5.98609 10.4118 5.10074 12.0257 5.10074C15.3829 5.10074 16 7.31053 16 10.1808V16.0461H15.9964Z"
        fill="#353538"
      />
    </svg>
  );
}
