import React from "react";
import Media from "react-media";

export const MobileContext = React.createContext([]);

export const MobileProvider = ({ children }) => {
  return (
    <Media queries={{
      mobile: "(max-width: 767px)",
      tablet: "(min-width: 768px) and (max-width: 1279px)",
      desktop: "(min-width: 1280px)"
    }}>
      {matches => (
        <MobileContext.Provider value={matches}>
          {children}
        </MobileContext.Provider>
      )}
    </Media>
  );
};
