import React from "react";

export default function TwitterIcon({ className }) {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        d="M14.7692 1.46751C14.2163 1.71259 13.6611 1.89761 13.0457 1.96008C13.6611 1.59005 14.1538 0.974929 14.399 0.297334C13.7836 0.667368 13.1683 0.912456 12.4928 1.0374C11.8774 0.422281 11.0769 0.0522461 10.2163 0.0522461C8.55288 0.0522461 7.19952 1.40503 7.19952 3.06538C7.19952 3.31287 7.19952 3.55796 7.26202 3.74298C4.73798 3.62043 2.52404 2.45026 1.04567 0.604895C0.737981 1.0374 0.615384 1.59005 0.615384 2.1427C0.615384 3.19033 1.16827 4.11301 1.96875 4.66566C1.47596 4.60319 0.985577 4.48064 0.615384 4.29563C0.615384 4.29563 0.615384 4.29563 0.615384 4.3581C0.615384 5.83343 1.66106 7.06367 3.01442 7.31116C2.76923 7.37123 2.52404 7.43371 2.21635 7.43371C2.03125 7.43371 1.84615 7.43371 1.66106 7.37123C2.03125 8.60148 3.13942 9.46409 4.49279 9.46409C3.44711 10.2642 2.15385 10.7544 0.737981 10.7544C0.492788 10.7544 0.245193 10.7544 0 10.6943C1.35337 11.5545 2.95433 12.0471 4.61538 12.0471C10.2163 12.0471 13.2308 7.43371 13.2308 3.43542C13.2308 3.31287 13.2308 3.19033 13.2308 3.06538C13.8461 2.63528 14.3389 2.08263 14.7692 1.46751Z"
        fill="#353538"
      />
    </svg>
  );
}
