import React from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import Footer from '../../components/Desktop/Footer'
import SharePage from '../../components/Desktop/SharePage'

export default function PageThree({ children }) {
  return (
    <div className="body-page">
      <Helmet>
        <title>I dati e l'industria</title>
      </Helmet>
      <div className="row">
        <div className="offset-md-1 col-md-2">
          <SharePage
            url="https://stories.efficienzaenergetica.edison.it/soluzioni-digitali/dati-e-industria/"
            page="three"
            classColor="color-page-3"
          />
        </div>
        <div className="col-md-8">
          <div className="d-flex align-items-center">
            <h1 className="title-page color-page-3">
              L'ottimizzazione energetica tramite i dati
            </h1>
            <div className="tempo-di-lettura d-flex align-items-center">
              <div className="linea bg-page-3 "></div>
              <div className="bg-page-3 text-white box-lettura">4′</div>
            </div>
          </div>
          <div className="text-page">
            <div>
              <div className="number-page color-page-3">3</div>
              <div>
                Il dato energetico è, per sua stessa natura, centrale nel
                settore industriale, perché permette di valutare le prestazioni
                energetiche del cliente e di conseguenza provare a migliorarle.
                Leggere in maniera intelligente i numeri, inoltre, consente un
                maggiore controllo e consapevolezza sulla propria struttura
                produttiva, nonché di ottenere informazioni utili da sfruttare
                nelle proprie strategie.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row consumi-aziende">
        <div className="offset-md-1 col-md-10 d-flex justify-content-center">
          <div className="d-flex align-items-center">
            <div className="text-center">
              <div>
                Quanta energia consumano
                <br />
                le aziende
              </div>
              <span className="big-number color-page-3">20</span>
              <span className="big-number-unit color-page-3">%</span>
              <div>
                I consumi energetici delle
                <br />
                aziende rispetto ai consumi
                <br />
                totali di energia a livello
                <br />
                nazionale
              </div>
              <div className="mt-3 mb-3">
                <img src={'/arrow-down-page-three.svg'} alt="Arrow Down" />
              </div>
              <div className="slogan-strong">
                Questo equivale ad avere
                <br />
                un’enorme mole di dati
                <br />
                energetici disponibili
              </div>
            </div>
            <div className="text-page-three  border-left-page-three">
              <div>
                La gestione energetica e ambientale di qualsiasi “sistema” non è
                una formula matematica, ma un articolato insieme di opzioni che
                richiede competenze e tempo per essere valutato. Spesso,
                infatti, è difficile far comprendere all’interlocutore la
                differenza tra la massimizzazione di valore economico nel breve
                periodo, e la minimizzazione del costo complessivo di una
                gestione ottimizzata nel tempo: se fino a poco tempo fa era
                inimmaginabile leggere insieme al cliente i “dati”, ora tutto
                questo è possibile e sempre più frequente.
              </div>
              <div className="mt-4">
                A partire dalla raccolta, gestione e valorizzazione dei dati,
                Edison ha configurato una gamma di servizi digitali che
                prospettano soluzioni dinamiche,specializzate e al contempo
                personalizzate, per accompagnare le aziende nella costruzione di
                valore e per generare un risparmio economico e ambientale.
              </div>
              <div className="mt-5">
                Edison propone alle realtà industriali opportunità a partire
                proprio dall’analisi del dato e del suo utilizzo. L'azienda fa
                questo attraverso il monitoraggio e l’ottimizzazione dei consumi
                in tempo reale: al fine di individuare nuove aree di efficienza,
                sfrutta algoritmi basati su intelligenza artificiale e machine
                learning per controllare gli asset di campo e realizzare
                importanti risparmi energetici.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-10">
          <div className="subtitle color-page-3">
            LA DIVISIONE EDISON SERVIZI ENERGETICI E AMBIENTALI NEL 2019
          </div>
          <div className="border-top-bottom-three">
            <img
              src={'/infografica-page-3.svg'}
              alt="Infografica Edison"
              className="m-auto"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-2 d-flex align-items-center">
          <div className="external-link-page-3">
            <a
              href="https://www.edison.it/it/edison-metron-consumi-energetici"
              target="_blank"
              title="Partnership con Metron"
              rel="noopener noreferrer"
            >
              La <span className="color-page-3">partnership</span>
              <br />
              con Metron:
              <br /> leggi il comunicato <span className="color-page-3">↗</span>
            </a>
          </div>
        </div>
        <div className="col-md-8">
          <div className="text-page">
            <div>
              In questo filone si inserisce ad esempio l’accordo siglato da
              Edison con Metron, per il servizio{' '}
              <span className="link-page-3">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Edison analytics powered by Metron"
                  href="https://www.efficienzaenergetica.edison.it/servizi/soluzioni-digitali/Edison-analytics-powered-by-metron/"
                >
                  Edison Analytics powered by Metron
                </a>
              </span>
              , volto a offrire ai clienti industriali gli strumenti necessari
              per essere consapevoli rispetto a quanto e come consumano, così
              come il know-how per definire strategie energetiche di lungo
              periodo, grazie all’Intelligenza Artificiale basata sul machine
              learning.
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-1">
          <img
            className="position-absolute bottom-0"
            src={'/vincent-sciandra.png'}
            alt="Vincent Sciandra"
          />
        </div>
        <div className="col-md-9">
          <div className="color-page-3 citazione citazione-page-3">
            Questa nuova partnership sottolinea l’emergere di una nuova e
            diffusa consapevolezza all’interno dell’industria italiana, che si
            traduce in una forte volontà di muoversi verso un ecosistema più
            sostenibile.
          </div>
          <div className="color-page-3 citazione-nome">
            Vincent Sciandra, Ceo di Metron
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-3 col-md-8">
          <div className="text-page">
            <div>
              Alla consapevolezza e alla conoscenza maggiore dei propri consumi,
              la soluzione Edison Analytics powered by Metron unisce poi la
              possibilità di correlare e contestualizzare i dati che sono stati
              raccolti, così da proporre soluzioni avanzate di business che
              integrano dati di campo, kpi di produzione e altri fattori.
            </div>
            <div className="mt-4">
              In definitiva, le competenze assodate di ingegneria energetica di
              Edison si intrecciano oggi con un nuovo know-how di information
              technology. Questo permette di generare un nuovo modo di lavorare,
              reattivo e votato all’innovazione digitale, che accompagna i
              clienti nella presa di coscienza rispetto alla potenzialità dei
              loro dati energetici.
              <span className="ml-1">
                <img src={'/edison-page-three-mini.svg'} alt="Edison" />
              </span>
              <Link to={'/le-proposte-edison'}>
                <span className="color-page-3 ml-1 continua-link">
                  continua con il capitolo 4
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>
      <Footer
        className="border-footer-page-three"
        iconClass="icon-page-3"
        iconSecondClass="icon-stroke-page-3"
      />
    </div>
  )
}
