import React, { useState, useCallback, useEffect } from "react";
import Fade from "react-reveal/Fade";

export default function MenuMobile({ titlePage, setTitlePage }) {
  const [menuOpen, setMenu] = useState(false);

  const toggleMenu = useCallback(() => {
    setMenu((menuOpen) => !menuOpen);
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [menuOpen]);

  return (
    <>
      <div className="menu-mobile">
        <div className="menu-icons">
          <div className="d-flex justify-content-between">
            <div
              className="pointer"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img src={"/icons/logo-mobile.svg"} alt="Logo Edison" />
            </div>
            <div className={titlePage.color}>{titlePage.title}</div>
            <div onClick={toggleMenu} className="pointer">
              {menuOpen ? (
                <img src={"/icons/close-menu.svg"} alt="Menu" />
              ) : (
                <img src={"/icons/burger-menu.svg"} alt="Menu" />
              )}
            </div>
          </div>
        </div>
      </div>
      {menuOpen && (
        <div className="menu-open">
          <div className="menu-elements">
            <Fade delay={200}>
              <a
                className="item-menu-mobile bg-page-1"
                rel="noopener noreferrer"
                href="#page1"
                onClick={() => {
                  setTitlePage({
                    title: "EDISON E IL DIGITALE",
                    color: "color-page-1",
                  });
                  toggleMenu();
                }}
              >
                <div>Edison e il digitale</div>
              </a>
            </Fade>
            <Fade delay={300}>
              <a
                className="item-menu-mobile bg-page-2"
                rel="noopener noreferrer"
                href="#page2"
                onClick={toggleMenu}
              >
                <div>Cos’è l’Industria 4.0</div>
              </a>
            </Fade>
          </div>
          <div className="menu-elements">
            <Fade delay={400}>
              <a
                className="item-menu-mobile bg-page-3"
                rel="noopener noreferrer"
                href="#page3"
                onClick={toggleMenu}
              >
                <div>I dati e l’industria</div>
              </a>
            </Fade>
            <Fade delay={500}>
              <a
                className="item-menu-mobile bg-page-4"
                rel="noopener noreferrer"
                href="#page4"
                onClick={toggleMenu}
              >
                <div>Le soluzioni digitali</div>
              </a>
            </Fade>
          </div>
        </div>
      )}
    </>
  );
}
