import React from "react";

export default function LinkedinCircleIcon({ className }) {
  return (
    <svg
      width="30"
      height="30"
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="14.5" fill="white" stroke="black" />
      <path
        d="M10.452 9.2V19H11.838V9.2H10.452ZM13.7329 12.126V19H15.0489V15.5C15.0489 13.932 15.8749 13.092 17.1069 13.092C18.2129 13.092 18.8849 13.666 18.8849 14.926V19H20.1869V14.856C20.1869 13.064 19.0249 12 17.4849 12C16.1269 12 15.4689 12.616 15.1609 13.232H15.0209V12.126H13.7329Z"
        fill="black"
      />
    </svg>
  );
}
