import React from "react";
import "./App.css";
import PageOne from "./pages/Desktop/PageOne";
import PageTwo from "./pages/Desktop/PageTwo";
import PageThree from "./pages/Desktop/PageThree";
import PageFour from "./pages/Desktop/PageFour";
import Container from "./components/Container";
import { MobileProvider } from "./providers/MobileProvider";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <MobileProvider>
        <Container>
          <Switch>
            <Route path="/edison-e-il-digitale">
              <PageOne />
            </Route>
            <Route path="/industria-4-0">
              <PageTwo />
            </Route>
            <Route path="/dati-e-industria">
              <PageThree />
            </Route>
            <Route path="/le-proposte-edison">
              <PageFour />
            </Route>
            <Route path="/">
              <PageOne />
            </Route>
          </Switch>
        </Container>
      </MobileProvider>
    </Router>
  );
}

export default App;
