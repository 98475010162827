import React from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import Footer from '../../components/Desktop/Footer'
import SharePage from '../../components/Desktop/SharePage'

export default function PageTwo({ children }) {
  return (
    <div className="body-page">
      <Helmet>
        <title>L’importanza dell’Industria 4.0</title>
      </Helmet>
      <div className="row">
        <div className="offset-md-1 col-md-2">
          <SharePage
            url="https://stories.efficienzaenergetica.edison.it/soluzioni-digitali/cose-l-industria-4-0/"
            page="two"
            classColor="color-page-2"
          />
        </div>
        <div className="col-md-8">
          <div className="d-flex align-items-center">
            <h1 className="title-page color-page-2">
              Tutto sulla 4° rivoluzione industriale
            </h1>
            <div className="tempo-di-lettura d-flex align-items-center">
              <div className="linea bg-page-2 "></div>
              <div className="bg-page-2 text-white box-lettura">5′</div>
            </div>
          </div>
          <div className="text-page">
            <div>
              <div className="number-page color-page-2">2</div>
              <div>
                L'industria 4.0 è il livello di frontiera delle imprese
                industriali e manifatturiere in cui, grazie alle tecnologie
                digitali, vengono adottati sistemi hi-tech che consentono di
                aumentare la produttività degli impianti, affinare la qualità
                dei prodotti e migliorare le condizioni e gli ambienti di
                lavoro.
              </div>
              <div className="mt-4">
                L’Industria 4.0 fa dunque riferimento a quell’insieme di
                innovazioni digitali che, venuto a maturazione negli ultimi anni
                principalmente nel terziario avanzato, sta oggi cercando un
                nuovo spazio nel mondo industriale: un’ondata di novità che ha
                dato inizio a una vera e propria rivoluzione – la quarta per
                l’appunto, che segue quella innescata dalla macchina a vapore,
                quella dell’elettricità e dalla produzione di massa, e quella
                nata con la prima informatizzazione.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-1">
          <img
            className="position-absolute bottom-0"
            src={'/giovanni-miragliotta.png'}
            alt="Giovanni Miragliotta"
          />
        </div>
        <div className="col-md-9">
          <div className="color-page-2 citazione citazione-page-2">
            Ormai quasi tutte le aziende italiane hanno compreso l’urgenza della
            trasformazione digitale: l’80 per cento ha la percezione della
            discontinuità rappresentata dall’Industria 4.0, e sa che il percorso
            è solo agli inizi.
          </div>
          <div className="color-page-2 citazione-nome">
            Giovanni Miragliotta, Direttore dell’Osservatorio Industria 4.0 del
            Politecnico di Milano
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-2"></div>
        <div className="col-md-8">
          <div className="text-page">
            <div>
              Era il 2011 quando si parlò per la prima volta di Industria 4.0 –
              alla fiera di Hannover, nel nord della Germania – e un anno dopo
              un gruppo di ingegneri presentò al governo federale tedesco il
              primo piano di implementazione di Industria 4.0; nel 2016 anche
              l’italia si allinea a questo trend, con l’introduzione del Piano
              Nazionale Industria 4.0, che detta i processi, gli assetti di
              governance, il quadro giuridico e gli strumenti di natura
              finanziaria utili allo sviluppo dell'industria di quarta
              generazione.
            </div>
            <div className="mt-4">
              Oggi uno dei pilastri dell’Industria 4.0 sono le tecnologie
              digitali e l’impatto della loro implementazione nei processi
              operativi delle aziende industriali: queste spaziano dall‘Internet
              of things ai big data, fino al cloud computing, così come dalla
              robotica collaborativa alla realtà aumentata.
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-10">
          <div className="subtitle color-page-2">
            QUANTO VALE L’INDUSTRIA 4.0 IN ITALIA
          </div>
          <hr className="hr-page-2" />
          <div className="d-flex justify-content-around">
            <div>
              <span className="big-number color-page-2">3.2</span>
              <span className="big-number-unit color-page-2">MLD</span>
              <div className="text-left font-medium">
                Il valore (€) del mercato italiano
                <br />
                dei progetti di Industria 4.0 (2018)
              </div>
            </div>
            <div>
              <span className="big-number color-page-2">+35</span>
              <span className="big-number-unit color-page-2">%</span>
              <div className="text-center font-medium">
                Il tasso di crescita a pari merito rispetto
                <br />
                ai risultati dell’anno precedente (2017)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-10">
          <div className="subtitle color-page-2">
            Il Valore delle TECNOLOGIE 4.0 PIù DIFFUSE IN ITALIA
          </div>
          <hr className="hr-page-2 margin-bottom-12" />
          <div>
            <img
              src={'/infografica-page-2.svg'}
              alt="Infografica"
              className="m-auto"
              width="100%"
            />
          </div>
          <hr className="hr-page-2 margin-top-12 margin-bottom-0" />
        </div>
      </div>
      <div className="row margin-top-48 margin-bottom-48">
        <div className="offset-md-1 col-md-2"></div>
        <div className="col-md-8">
          <div className="text-page margin-top-0">
            <div>
              La quarta rivoluzione industriale può inoltre contribuire a
              centrare in tempo gli obiettivi di sostenibilità (Sustainable
              development goals – Sdg) fissati dalle Nazioni Unite per il 2030:
              è quanto rivela una ricerca del World Economic Forum, mostrando
              che già con l’avanzamento tecnologico si può avere un impatto
              significativo su 10 dei 17 Sdg previsti, nonché sul 70 per cento
              dei 169 target stabiliti dalle Nazioni Unite.
            </div>
            <div className="mt-4">
              Anche il settore energetico trae particolare giovamento dalla
              digitalizzazione: abbattere i costi in questo campo è infatti tra
              i primi obiettivi delle aziende in fase di crescita;
              l’ottimizzazione energetica, passando inevitabilmente dal
              monitoraggio dei consumi, può essere raggiunta velocemente
              sfruttando le possibilità delle nuove tecnologie, l’Intelligenza
              Artificiale, la data analysis.
            </div>
            <div className="mt-4">
              Questo oggi consiste in primo luogo nella consapevolezza di
              consumi e costi, resi finalmente più chiari dall’utilizzo di Iot,
              della data visualization, di cloud storage e dal successivo
              impiego della grande mole di informazioni raccolte dalle utility
              per modificare i processi produttivi in ottica di risparmio – di
              potenza allocata, di flussi di produzione ma non di valore.
              <span className="ml-1">
                <img src={'/edison-page-two-mini.svg'} alt="Edison" />
              </span>
              <Link to={'/dati-e-industria'}>
                <span className="color-page-2 ml-1 continua-link">
                  continua con il capitolo 3
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-10">
          <div className="subtitle margin-top-0 color-page-2">
            I BENEFICI DEI PROGETTI DI INDUSTRIA 4.0 secondo le aziende
          </div>
          <hr className="hr-page-2" />
          <div className="color-page-2 text-center mini-subtitle">
            I 4 principali benefici indicati dalla aziende con progetti di
            industria 4.0 attivi da oltre un anno
          </div>
          <div className="row margin-top-32">
            <div className="offset-md-1 col-md-5 d-flex align-items-center">
              <div className="medium-number color-page-2">47%</div>
              <div className="medium-number-label">
                Migliore flessibilità{'  '}
                <br />
                nella produzione
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="medium-number color-page-2">38%</div>
              <div className="medium-number-label">
                Aumento dell’efficienza
                <br />
                dell’impianto industriale
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-md-1 col-md-5 d-flex align-items-center">
              <div className="medium-number color-page-2">34%</div>
              <div className="medium-number-label">
                Riduzione dei tempi
                <br />
                di progettazione
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="medium-number color-page-2">33%</div>
              <div className="medium-number-label">
                Opportunità di sviluppare
                <br />
                prodotti innovativi
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        className="border-footer-page-two"
        iconClass="icon-page-2"
        iconSecondClass="icon-stroke-page-2"
      />
    </div>
  )
}
