import React from "react";
import FacebookIcon from "../Desktop/icons/FacebookIcon";
import TwitterIcon from "../Desktop/icons/TwitterIcon";
import InstagramIcon from "../Desktop/icons/InstagramIcon";
import LinkedinIcon from "../Desktop/icons/LinkedinIcon";
import YoutubeIcon from "../Desktop/icons/YoutubeIcon";
import FacebookCircleIcon from "../Desktop/icons/FacebookCircleIcon";
import LinkedinCircleIcon from "../Desktop/icons/LinkedinCircleIcon";
import TwitterCircleIcon from "../Desktop/icons/TwitterCircleIcon";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import SendMessageIcon from "../Desktop/icons/SendMessageIcon";

export default function Footer({ className, iconClass, iconSecondClass }) {

  const globalUrl = 'https://stories.efficienzaenergetica.edison.it/soluzioni-digitali/'

  return (
    <div className="row">
      <div className={`Footer ${className} offset-md-1 col-md-10`}>
        <div className="row">
          <div className="col-sm-12">
            <img src={"/logo-footer.svg"} alt="Edison" />
          </div>
          <div className="col-sm-12 margin-top-32">
            <div className="d-flex">
              <div>
                <div className="subtitle-footer">
                  Condividi l’intero
                  <br />
                  progetto editoriale
                </div>
                <div className="d-flex mt-2">
                  <div>
                    <FacebookShareButton url={globalUrl}>
                      <FacebookCircleIcon className={iconSecondClass} />
                    </FacebookShareButton>
                  </div>
                  <div className="ml-2">
                    <TwitterShareButton url={globalUrl}>
                      <TwitterCircleIcon className={iconSecondClass} />
                    </TwitterShareButton>
                  </div>
                  <div className="ml-2" url={globalUrl}>
                    <LinkedinShareButton>
                      <LinkedinCircleIcon className={iconSecondClass} />
                    </LinkedinShareButton>
                  </div>
                </div>
              </div>
              <div className="ml-5">
                <div className="subtitle-footer">
                  Scrivici un messaggio
                  <br />
                  ti ricontatteremo noi
                </div>
                <div className="mt-2">
                  <a
                    href="https://www.efficienzaenergetica.edison.it/contatti/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <SendMessageIcon className={iconSecondClass} />
                  </a>
                </div>
              </div>
            </div>
            <div className="margin-top-24 d-flex">
              <div>
                <a
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/EdisonS.p.a/"
                  target="_blank"
                >
                  <FacebookIcon className={iconClass} />
                </a>
              </div>
              <div className='ml-4'>
                <a
                  rel="noopener noreferrer"
                  href="https://twitter.com/EdisonNews"
                  target="_blank"
                >
                  <TwitterIcon className={iconClass} />
                </a>
              </div>
              <div className='ml-4'>
                <a
                  href="https://www.instagram.com/edison_onoff/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <InstagramIcon className={iconClass} />
                </a>
              </div>
              <div className='ml-4'>
                <a
                  href="https://it.linkedin.com/company/edison-spa"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <LinkedinIcon className={iconClass} />
                </a>
              </div>
              <div className='ml-4'>
                <a
                  href="https://www.youtube.com/channel/UCOzAKqdXJYVeuvv_kCxsXiA"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <YoutubeIcon className={iconClass} />
                </a>
              </div>
            </div>
            <div className="d-flex privacy margin-top-24">
              <div>
                <a
                  href="https://edisonenergia.it/edison/informativa-privacy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy
                </a>
              </div>
              <div>
                <a
                  href="https://edisonenergia.it/edison/cookie-policy"
                  rel="noopener noreferrer"
                  className='ml-4'
                  target="_blank"
                >
                  Informativa Cookies
                </a>
              </div>
            </div>
            <div className="small-footer margin-top-24">
              Copyright © 2020 Edison Spa <br />
              Foro Buonaparte 31, 20121 Milano
            </div>
          </div>
          <div className="col-sm-12 margin-top-16">
            <div className="small-footer">
              Capitale Soc. euro 5.377.000.671,00 i.v.
              <br />
              Reg. Imprese di Milano – Monza – Brianza – Lodi
              <br />
              C.F. 06722600019 – Partita IVA 08263330014
              <br />
              REA di Milano 1698754 – PEC: edison@pec.edison.it
            </div>
            <div className="small-footer margin-top-8">
              Fonte dati: Osservatorio Industria 4.0
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
