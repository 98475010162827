import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import ScrollProgressRead from 'react-scroll-progress-read'
import MenuMobile from './Mobile/MenuMobile'
import Footer from './Mobile/Footer'
import PageOne from '../pages/Mobile/PageOne'
import PageTwo from '../pages/Mobile/PageTwo'
import PageThree from '../pages/Mobile/PageThree'
import PageFour from '../pages/Mobile/PageFour'
import RotateSmartphone from './Mobile/RotateSmartphone'

export default function MobileContainer() {
  const [titlePage, setTitlePage] = useState(false)
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const history = useHistory()

  return (
    <>
      <div className="position-relative mobile-pages">
        <div className="position-sticky top-menu">
          <MenuMobile setTitlePage={setTitlePage} titlePage={titlePage} />
          <div className="bar-progress d-flex">
            <ScrollProgressRead
              backgroundColor="#FFF"
              barColor="#00D739"
              height="5px"
              target="page1"
              refTarget={ref1}
            />
            <ScrollProgressRead
              backgroundColor="#FFF"
              barColor="#1ABCFE"
              height="5px"
              target="page2"
              refTarget={ref2}
            />
            <ScrollProgressRead
              backgroundColor="#FFF"
              barColor="#FFB400"
              height="5px"
              target="page3"
              refTarget={ref3}
            />
            <ScrollProgressRead
              backgroundColor="#FFF"
              barColor="#F24E1E"
              height="5px"
              target="page4"
              refTarget={ref4}
            />
          </div>
        </div>

        <div id="body-mobile" className="body-mobile">
          <Waypoint
            onEnter={() => {
              history.push('/edison-e-il-digitale')
              setTitlePage({
                title: 'EDISON E IL DIGITALE',
                color: 'color-page-1',
              })
            }}
            fireOnRapidScroll={false}
          >
            <div ref={ref1} id="page1">
              <PageOne />
            </div>
          </Waypoint>
          <hr className="hr-divide-page-1" />
          <Waypoint
            onEnter={() => {
              history.push('/industria-4-0')
              setTitlePage({
                title: 'COS’È L’INDUSTRIA 4.0',
                color: 'color-page-2',
              })
            }}
            fireOnRapidScroll={false}
          >
            <div ref={ref2} id="page2">
              <PageTwo />
            </div>
          </Waypoint>
          <hr className="hr-divide-page-2" />
          <Waypoint
            onEnter={() => {
              history.push('/dati-e-industria')
              setTitlePage({
                title: "I DATI E L'INDUSTRIA",
                color: 'color-page-3',
              })
            }}
            fireOnRapidScroll={false}
          >
            <div ref={ref3} id="page3">
              <PageThree />
            </div>
          </Waypoint>
          <hr className="hr-divide-page-3" />
          <Waypoint
            onEnter={() => {
              history.push('/le-proposte-edison')
              setTitlePage({
                title: 'LE SOLUZIONI DIGITALI',
                color: 'color-page-4',
              })
            }}
            fireOnRapidScroll={false}
          >
            <div ref={ref4} id="page4">
              <PageFour />
            </div>
          </Waypoint>
          <hr className="hr-divide-page-4" />
        </div>
        <Footer />
      </div>
      <RotateSmartphone />
    </>
  )
}
