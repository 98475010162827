import React from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import Footer from '../../components/Desktop/Footer'
import SharePage from '../../components/Desktop/SharePage'

export default function PageFour({ children }) {
  return (
    <div className="body-page">
      <Helmet>
        <title>Le soluzioni digitali di Edison</title>
      </Helmet>
      <div className="row">
        <div className="offset-md-1 col-md-2">
          <SharePage
            url="https://stories.efficienzaenergetica.edison.it/soluzioni-digitali/le-proposte-edison/"
            page="four"
            classColor="color-page-4"
          />
        </div>
        <div className="col-md-8">
          <div className="d-flex align-items-center">
            <h1 className="title-page color-page-4">
              Le soluzioni digitali per l’Industria 4.0
            </h1>
            <div className="tempo-di-lettura d-flex align-items-center">
              <div className="linea bg-page-4 "></div>
              <div className="bg-page-4 text-white box-lettura">5′</div>
            </div>
          </div>
          <div className="text-page">
            <div>
              <div className="number-page color-page-4">4</div>
              <div>
                Alla base delle proposte digitali di Edison trovano spazio la
                raccolta, la comprensione, la visualizzazione e, infine, la
                valorizzazione del dato – tutti aspetti, questi, che integrati
                permettono agli interlocutori di Edison di sperimentare un nuovo
                modo di fare business.
              </div>
              <div className="mt-4">
                Edison è in grado di comprendere e presidiare tutte le fasi
                della catena del valore del dato: dalla rilevazione attenta alla
                conoscenza dei KPI “di campo”, dalla gerenza del dato (come la
                trasmissione, la gestione di data platform o la data storage) al
                suo utilizzo secondo standard di privacy e norme – è la
                cosiddetta data quality.
              </div>
              <div className="mt-4">
                Le competenze di Edison all’interno del settore digitale sono
                frutto dell’esperienza maturata sul campo: l’azienda infatti
                unisce le tradizionali attività di gestione e manutenzione degli
                impianti e di ingegneria energetica con la forte conoscenza
                nella raccolta e gestione dei dati.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-10">
          <div className="subtitle color-page-4">
            RENDERE VISIBILE LA SPESA ENERGETICA
          </div>
          <hr className="hr-page-4" />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="offset-md-2 col-md-2 color-page-4 number-page-4">1</div>
        <div className="col-md-2 label-page-4 color-page-4 font-weight-bold">
          Rilevazione
          <br />
          dati
        </div>
        <div className="col-md-4 description-page-4">
          Analisi e mappatura situazione di campo,
          <br />e progettazione e installazione di sistemi
          <br />
          di rilevazione-dati.
        </div>
      </div>
      <hr className="border-bottom-page-4 offset-md-2 col-md-8" />
      <div className="row align-items-center">
        <div className="offset-md-2 col-md-2 color-page-4 number-page-4">2</div>
        <div className="col-md-2 label-page-4 color-page-4 font-weight-bold">
          Gestione
          <br />
          dati
        </div>
        <div className="col-md-4 description-page-4">
          Progettazione e realizzazione di sistemi
          <br />
          di trasmissione e aggregazione dati,
          <br />e gestione della data quality.
        </div>
      </div>
      <hr className="border-bottom-page-4 offset-md-2 col-md-8" />
      <div className="row align-items-center">
        <div className="offset-md-2 col-md-2 color-page-4 number-page-4">3</div>
        <div className="col-md-2 label-page-4 color-page-4 font-weight-bold">
          Servizi di
          <br />
          ottimizzazione
        </div>
        <div className="col-md-4 description-page-4">
          Ottimizzazione dei consumi energetici
          <br />
          basata sull’analisi avanzata dei dati,
          <br />
          con il controllo dei parametri operativi.
        </div>
      </div>
      <hr className="border-bottom-page-4 offset-md-2 col-md-8" />
      <div className="row align-items-center">
        <div className="offset-md-2 col-md-2 color-page-4 number-page-4">4</div>
        <div className="col-md-2 label-page-4 color-page-4 font-weight-bold">
          Monitoraggio
          <br />
          KPI e analisi dati
        </div>
        <div className="col-md-4 description-page-4">
          Soluzioni real time di monitoraggio, analisi
          <br />e previsione dei consumi elettrici, termici
          <br />e CO2 per i processi industriali e gli edifici
        </div>
      </div>
      <hr className="border-bottom-page-4 offset-md-2 col-md-8" />
      <div className="row align-items-center">
        <div className="offset-md-2 col-md-2 color-page-4 number-page-4">5</div>
        <div className="col-md-2 label-page-4 color-page-4 font-weight-bold">
          Business Energy
          <br />
          Intelligence
        </div>
        <div className="col-md-4 description-page-4">
          Analisi avanzata dei consumi energetici
          <br />
          per l’identificazione, valutazione e<br />
          progettazione di opportunità efficienziali.
        </div>
      </div>
      <hr className="border-bottom-page-4 offset-md-2 col-md-8" />
      <div className="row">
        <div className="offset-md-3 col-md-8 text-page">
          <div>
            In ambito industriale Edison opera con{' '}
            <span className="link-page-4">
              <a
                target="_blank"
                title="Soluzioni di Energy Management System"
                rel="noopener noreferrer"
                href="https://www.efficienzaenergetica.edison.it/servizi/soluzioni-digitali/"
              >
                soluzioni di Energy Management System
              </a>
            </span>{' '}
            che permettono la raccolta, consuntivazione e la gestione dei dati
            energetici e operativi:{' '}
            <span className="link-page-4">
              <a
                href="https://www.efficienzaenergetica.edison.it/servizi/soluzioni-digitali/edison-smart-audit/"
                target="_blank"
                title="Smart Audit"
                rel="noopener noreferrer"
              >
                Smart Audit
              </a>
            </span>
            , dedicato alle medie imprese energivore, nonché alle Grandi
            imprese, soprattutto del settore Terziario; ed Edison Analitycs
            powered by Metron che si rivolge alle grandi aziende.
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-3 col-md-1">
          <div className="text-page">
            <img src={'/number-one-red.svg'} alt="Number One" />
          </div>
          <div className="external-link-vertical-16 color-page-4">
            PMI e terziario
          </div>
        </div>
        <div className="col-md-7">
          <div className="text-page">
            Smart Audit è un servizio di monitoraggio, analisi e forecasting dei
            consumi energetici, che include report periodici di consulenza
            energetica e diagnosi energetica, consentendo un risparmio fino al
            10% dei consumi energetici totali grazie agli interventi gestionali.
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-3 col-md-1">
          <div className="text-page">
            <img src={'/number-2-red.svg'} alt="Number Two" />
          </div>
          <div className="external-link-vertical-16 color-page-4">
            Grandi aziende
          </div>
        </div>
        <div className="col-md-7">
          <div className="text-page">
            Edison Analytics powered by Metron invece sfrutta le fonti di dati
            già esistenti nelle industrie italiane: la piattaforma digitale
            raccoglie, aggrega e analizza in tempo reale i dati generati
            dall’impianto, interagendo al contempo i dati provenienti
            dall’esterno, permettendo di identificare opportunità di
            ottimizzazione energetica.
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-1">
          <img
            className="position-absolute bottom-0"
            src={'/paolo-quaini-2.png'}
            alt="Paolo Quaini"
          />
        </div>
        <div className="col-md-9">
          <div className="color-page-4 citazione citazione-page-4">
            La soluzione si può applicare a tutti i processi industriali di
            qualsiasi settore produttivo, ed è in grado di prevedere le future
            esigenze energetiche di un impianto, individuare nuove opportunità e
            soluzioni di efficientamento, e se necessario ottimizzare anche la
            gestione delle singole macchine.
          </div>
          <div className="color-page-4 citazione-nome">
            Paolo Quaini, Direttore Edison Servizi Energetici e Ambientali
          </div>
        </div>
      </div>
      <div className="row consumi-aziende">
        <div className="offset-md-1 col-md-10  d-flex justify-content-center">
          <div className="d-flex">
            <div className="text-center border-right-page-four">
              <div>Quanto si può risparmiare</div>
              <span className="big-number color-page-4">14</span>
              <span className="big-number-unit color-page-4">%</span>
              <div>
                Il risparmio consentito
                <br />
                da Edison Analytics powered
                <br />
                by Metron rispetto ai consumi
                <br />
                energetici totali
              </div>
              <div className="mt-3 mb-3">
                <img src={'/arrow-down-page-fuor.svg'} alt="Arrow Down" />
              </div>
              <div className="slogan-strong">
                Questo grazie all’analisi
                <br />
                dei dati storici e prevedendo
                <br />
                le esigenze energetiche
                <br />
                dell’impianto
              </div>
            </div>
            <div className="text-page-four">
              <div>
                La soluzione si fonda sull’impiego dell’Intelligenza
                <br />
                Artificiale, che utilizza modelli energetici evoluti in
                <br />
                grado di individuare e valutare correlazioni
                <br />
                non-intuitive tra un grande numero di variabili,
                <br /> cosa che consente – a sua volta – di tracciare
                <br />
                soluzioni di efficientamento.
              </div>
              <div className="mt-5">
                Attraverso algoritmi basati su AI, Edison Analytics
                <br />
                powered by Metron è quindi in grado di valutare i<br />
                setting ottimali degli impianti monitorati
                <br />
                garantendo l’ottimizzazione dei consumi,
                <br /> intervenendo direttamente e indirettamente sul
                <br />
                controllo degli impianti.
              </div>
              <div className="mt-5">
                Nello specifico, dopo aver creato un modello
                <br />
                digitale dell’impianto deputato vengono utilizzati il
                <br />
                machine learning e l’intelligenza artificiale per
                <br />
                ottimizzare i parametri operativi, riducendo i<br />
                consumi energetici.
                <span className="ml-1">
                  <img src={'/edison-page-fuor-mini.svg'} alt="Edison" />
                </span>
                <Link to={'/edison-e-il-digitale'}>
                  <span className="color-page-4 ml-1 continua-link">
                    torna al capitolo 1
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-10">
          <div className="subtitle color-page-4">
            CASI DI Ottimizzazione ENERGETICA nei processi industriali
          </div>
          <hr className="hr-page-4" />
        </div>
      </div>
      <div className="row">
        <div className="offset-md-3 col-md-4">
          <img src={'/key-icon.svg'} alt="Key Icon" />
        </div>
        <div className="col-md-4">
          <img src={'/document-icon.svg'} alt="Key Icon" />
        </div>
      </div>
      <div className="row margin-top-32">
        <div className="offset-md-1 col-md-2 color-page-4 label-page-4">
          Cliente
        </div>
        <div className="col-md-4 description-page-4">
          Grande industria siderurgica
          <br />e mineraria
        </div>
        <div className="col-md-4 description-page-4">
          Società di produzione di prodotti
          <br />
          in carta
        </div>
      </div>
      <div className="row margin-top-32">
        <div className="offset-md-1 col-md-2 color-page-4 label-page-4">
          Problema
        </div>
        <div className="col-md-4 description-page-4">
          Ottimizzare la gestione energetica
          <br />
          di una delle proprie fabbriche
        </div>
        <div className="col-md-4 description-page-4">
          Ridurre il processo e il consumo
          <br />
          di vapore delle macchine
        </div>
      </div>
      <div className="row margin-top-32">
        <div className="offset-md-1 col-md-2 color-page-4 label-page-4">
          Soluzione
        </div>
        <div className="col-md-4 description-page-4">
          Monitoraggio – continuo e in
          <br />
          tempo reale – e comunicazione
          <br />
          automatizzata dei consumi e dei
          <br />
          flussi di energia
        </div>
        <div className="col-md-4 description-page-4">
          Digitalizzazione delle macchine,
          <br />
          creando un “gemello digitale"
          <br />
          attraverso i dati, per visualizzare
          <br />e ottimizzare il consumo di energia
        </div>
      </div>
      <div className="row margin-top-32">
        <div className="offset-md-1 col-md-2 color-page-4 label-page-4">
          Risultati
        </div>
        <div className="col-md-4 description-page-4">
          Risparmio di tempo e riduzione del
          <br />
          proprio budget energetico a parità
          <br />
          di consumo
        </div>
        <div className="col-md-4 description-page-4">
          Risparmio di tempo e riduzione del
          <br />
          proprio budget energetico a parità
          <br />
          di consumo
        </div>
      </div>
      <div className="row margin-top-32">
        <div className="offset-md-1 col-md-2 color-page-4 label-page-4">
          Risparmi
        </div>
        <div className="col-md-4 description-page-4">
          340 mila €/anno
          <br />5 giorni-uomo/mese per la
          <br />
          raccolta dati risparmiati
        </div>
        <div className="col-md-4 description-page-4">
          270 mila €/anno
          <br />1 giorni-uomo/mese per la
          <br />
          raccolta dati risparmiati
        </div>
      </div>
      <Footer
        className="border-footer-page-four"
        iconClass="icon-page-4"
        iconSecondClass="icon-stroke-page-4"
      />
    </div>
  )
}
